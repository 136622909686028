@import ../../../styles/helpers

.head
    max-width: 600px
    margin: 0 auto 72px
    text-align: center
    +x
        margin-bottom: 96px
    +m
        margin-bottom: 64px

.stage
    margin-bottom: 8px
    +hairline-2
    color: $neutrals4

.title
    margin-bottom: 12px
    +m
        font-size: 32px

.wrapper
    position: relative
    margin: 0 -12px

.row
    display: flex
    align-items: center
    +t
        display: block

.details
    width: 600px
    text-align: center
    margin-left: 196px
    padding-bottom: 80px
    +x
        margin-left: 64px
    +d
        width: 304px
        margin-left: 32px
        padding-bottom: 64px
    +t
        width: 100%
        margin: 48px 0 0
        padding-bottom: 80px
    +m
        margin-top: 32px

.wrap
    margin-bottom: 75px
    padding: 10px 15px
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
    text-align: center
    +d
        margin-bottom: 32px
        padding: 24px 16px
    +dark
        background: $neutrals2
        border-color: $neutrals2
