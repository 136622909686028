@import ../../styles/helpers

.theme
    display: inline-block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 0

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    &:checked + .inner .box
        background: $blue
        &:before
            transform: translate(20px,-50%)
            background: $neutrals8

.inner
    position: relative
    display: inline-block
    transition: all .2s

.box
    position: relative
    display: block
    width: 40px
    height: 20px
    background: $neutrals6
    border-radius: 10px 
    transition: all .2s
    +dark
        background: $neutrals3
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 4px
        transform: translateY(-50%)
        width: 12px
        height: 12px
        border-radius: 50%
        background: $blue
        transition: all .2s 

.themeBig
    .box
        width: 48px
        height: 24px
        border-radius: 12px 
        &:before
            left: 4px
            width: 16px
            height: 16px
    .input
        &:checked + .inner .box
            &:before
                transform: translate(24px,-50%)
