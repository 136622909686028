@import ../../../styles/helpers

.head
    max-width: 600px
    margin: 0 auto 128px
    text-align: center
    +x
        margin-bottom: 112px
    +m
        margin-bottom: 64px

.stage
    margin-bottom: 8px
    +hairline-2
    color: $neutrals4

.title
    margin-bottom: 8px
    +m
        font-size: 32px

.wrapper
    position: relative
    margin: 0 -6px

.row
    display: flex
    align-items: center
    +t
        display: block
    display: flex
    flex-direction: column
    .button
        width: 100%
        &:not(:last-child)
            margin-bottom: 8px
