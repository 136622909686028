@import ../../../../styles/helpers

.item
    position: relative
    display: flex
    flex-direction: row-reverse
    color: $neutrals2
    transition: color .2s
    +t
        display: block
    +dark
        color: $neutrals8
    &:hover
        color: $blue
        .arrow
            transform: translateX(10px)
            svg
                fill: $blue

.preview
    flex-shrink: 0
    width: 640px
    margin-left: auto
    +d
        width: 500px
    +t
        width: 100%
        margin-bottom: 32px
    img
        width: 100%
        border-radius: 16px
        +t
            min-height: 240px
            object-fit: cover

.details
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 352px
    +d
        width: auto
        padding-right: 32px
    +t
        padding-right: 0

.category
    margin-bottom: auto
    padding: 0 10px
    background: $pink
    color: $white

.title
    margin: 24px 0 8px
    +t
        margin-top: 16px

.currency
    margin-bottom: 20px
    +body-2
    color: $neutrals3
    +dark
        color: $neutrals5

.content
    margin-bottom: auto
    color: $neutrals4

.arrow
    position: absolute
    top: calc(100% + 14px)
    left: 0
    width: 24px
    height: 24px
    transition: transform .2s
    +t
        position: static
        margin-top: 16px
    svg
        fill: $neutrals4
        transition: fill .2s
