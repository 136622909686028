@import ../../../styles/helpers

.main
    padding-top: 40px

.head
    margin: 0 auto 40px
    text-align: center
    +m
        margin-bottom: 12px

.title
    margin-bottom: 24px

.breadcrumbs
    justify-content: center

.button
    justify-content: center
    margin-top: 35px

.content
    text-align: justify

.actions
    position: relative
    display: flex
    justify-content: center
    margin-top: 80px
    +m
        margin-top: 32px

.actions > div,
.favorite
    &:not(:last-child)
        margin-right: 32px
